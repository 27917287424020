import fetch from 'unfetch';

class Posts {
  selector;
  elements;

  constructor(selector = '.h-loc-us') {
    this.selector = selector;
  }

  cache() {
    const { selector } = this;

    this.elements = selector instanceof NodeList ? selector : document.querySelectorAll(selector);
  }

  getLocation() {
    const p = new Promise((resolve, reject) => {
      const localLocation = localStorage.getItem('gzLocation');

      if (localLocation) {
        return resolve(localLocation);
      }

      fetch('https://freegeoip.app/json/', {
        method: 'get'
      })
        .then(response => {
          if (!response.ok) {
            return reject();
          }

          return response.json();
        })
        .then((data) => {
          if (!data || !data.country_code) {
            return reject();
          }

          this.saveLocation(data.country_code);

          return resolve(data.country_code);
        })
        .catch(() => {
          return reject();
        });
    });

    return p;
  }

  saveLocation(location) {
    if (location) {
      localStorage.setItem('gzLocation', location);
    }
  }

  initLocation() {
    const { elements } = this;

    this.getLocation()
      .then((countryCode) => {
        if (countryCode === 'US') {
          elements.forEach((element) => {
            element.classList.remove('h-loc-us');
          });
        }
      })
      .catch(() => {});
  }

  init() {
    const { selector } = this;

    if (selector) {
      this.cache();
      this.initLocation();
    }
  }
}

export default Posts;
