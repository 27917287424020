import Dropdown from './dropdown';

class Posts {
  selector;
  element;
  filtersElement;
  sortElement;
  sortDropdown;
  sortDropdownElement;

  constructor(selector = '.js-posts') {
    this.selector = selector;

    this.onSortChange = this.onSortChange.bind(this);
  }

  cache() {
    const { selector } = this;

    this.element = selector instanceof HTMLElement ? selector : document.querySelector(selector);

    if (!this.element) {
      return;
    }

    this.sortElement = this.element.querySelector('.js-posts-sort');
    this.sortDropdownElement = this.sortElement && this.sortElement.querySelector('.js-dropdown');
    this.sortDropdown = this.sortDropdownElement ? new Dropdown(this.sortDropdownElement, this.onSortChange) : null;
  }

  initPosts() {
    const { element } = this;

    if (!element) {
      return;
    }

    if (this.sortDropdown) {
      this.sortDropdown.init();
    }
  }

  onSortChange(e) {
    console.log(e.target.value);
  }

  init() {
    const { selector } = this;

    if (selector) {
      this.cache();
      this.initPosts();
    }
  }
}

export default Posts;
