import 'unfetch/polyfill';
import Header from './components/header';
import Languages from './components/languages';
import Reveal from './components/reveal';
import FormSubscribe from './components/form-subscribe';
import CarouselImages from './components/carousel-images';
import CarouselTiles from './components/carousel-tiles';
import CarouselResourcesWebinars from './components/carousel-resources-webinars';
import CarouselResourcesArticles from './components/carousel-resources-articles';
import FiltersTiles from './components/filters-tiles';
import Posts from './components/posts';
import ModalVideo from './components/modal-video';
import ScrollAccordion from './components/scroll-accordion';
import LocationElements from './components/location-elements';

class App {
  constructor() {
    this.header = new Header();
    this.languages = new Languages();
    this.reveal = new Reveal();
    this.formSubscribe = new FormSubscribe();
    this.carouselTiles = new CarouselTiles();
    this.carouselResourcesWebinars = new CarouselResourcesWebinars();
    this.carouselResourcesArticles = new CarouselResourcesArticles();
    this.filtersTiles = new FiltersTiles();
    this.posts = new Posts();
    this.modalVideo = new ModalVideo();
    this.scrollAccordion = new ScrollAccordion();
    this.locationElements = new LocationElements();
  }

  init() {
    this.header.init();
    this.languages.init();
    this.reveal.init();
    this.formSubscribe.init();
    this.carouselTiles.init();
    this.carouselResourcesWebinars.init();
    this.carouselResourcesArticles.init();
    this.filtersTiles.init();
    this.posts.init();
    this.modalVideo.init();
    this.scrollAccordion.init();
    this.locationElements.init();

    const carouselImagesList = document.querySelectorAll('.js-carousel-images');
    carouselImagesList.forEach((element) => {
      const carouselImages = new CarouselImages(element);
      carouselImages.init();
    });
  }
}

const app = new App();
app.init();
