import Dropdown from './dropdown';

class Posts {
  selector;
  elements;

  constructor(selector = '.js-language-switcher') {
    this.selector = selector;

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  cache() {
    const { selector } = this;

    this.elements = selector instanceof NodeList ? selector : document.querySelectorAll(selector);
  }

  initLanguages() {
    const { elements } = this;

    if (elements.length === 0) {
      return;
    }

    console.log(elements);

    elements.forEach((element) => {
      const languagesDropdownElement = element.querySelector('.js-dropdown');
      const languagesDropdown = languagesDropdownElement ? new Dropdown(languagesDropdownElement, this.onLanguageChange) : null;

      if (languagesDropdown) {
        languagesDropdown.init();
      }
    });

  }

  onLanguageChange(e) {
    if (e.target.value) {
      window.location.href = e.target.value;
    }
  }

  init() {
    const { selector } = this;

    if (selector) {
      this.cache();
      this.initLanguages();
    }
  }
}

export default Posts;
