class Dropdown {
  selector;
  element;
  selectElement;
  dropdownButtonElement;
  dropdownButtonLabelElement;
  dropdownItemsElement;

  constructor(selector, onChange) {
    this.selector = selector;
    this.onChange = onChange;
  }

  cache() {
    const { selector } = this;

    this.element = selector instanceof HTMLElement ? selector : document.querySelector(selector);
    this.selectElement = this.element && this.element.querySelector('.js-dropdown-select');
    this.dropdownButtonElement = this.element && this.element.querySelector('.js-dropdown-button');
    this.dropdownButtonLabelElement = this.element && this.element.querySelector('.js-dropdown-button-label');
    this.dropdownItemsElement = this.element && this.element.querySelector('.js-dropdown-items');
  }

  initDropdown() {
    const {
      element,
      onChange,
      selectElement,
      dropdownButtonElement,
      dropdownButtonLabelElement,
      dropdownItemsElement
    } = this;

    if (!element || !selectElement || !dropdownButtonElement || !dropdownItemsElement) {
      return;
    }

    element.classList.add('is-loaded');

    dropdownButtonElement.addEventListener('click', (e) => {
      e.preventDefault();
      element.classList.toggle('is-open');
    });

    const dropdownItems = dropdownItemsElement.querySelectorAll('.js-dropdown-item');
    dropdownItems.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        selectElement.value = item.value;
        selectElement.dispatchEvent(new Event('change'));

        dropdownButtonLabelElement.innerHTML = item.innerHTML;
        element.classList.remove('is-open');

        dropdownItems.forEach((i) => {
          i.classList.toggle('is-active', i === item);
        });
      });
    });

    if (onChange) {
      selectElement.addEventListener('change', onChange);
    }

    document.addEventListener('click', (e) => {
      if (!element.contains(e.target)) {
        element.classList.remove('is-open');
      }
    });

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        element.classList.remove('is-open');
      }
    });
  }

  init() {
    const { selector } = this;

    if (selector) {
      this.cache();
      this.initDropdown();
    }
  }
}

export default Dropdown;
