import Carousel from './carousel';

class CarouselResourcesWebinars extends Carousel {
  constructor(selector = '.js-carousel-resources-webinars', settings = {}) {
    super(selector, {
      ...settings,
      navigation: true,
      slidesPerView: 1.3,
      spaceBetween: 20,
      breakpoints: {
        768: {
          slidesPerView: 1,
          spaceBetween: 0
        }
      }
    });
  }
}

export default CarouselResourcesWebinars;
