import Carousel from './carousel';

class CarouselTiles extends Carousel {
  constructor(selector = '.js-carousel-tiles', settings = {}) {
    super(selector, {
      ...settings,
      autoplay: true,
      slidesPerView: 1.3,
      spaceBetween: 20
    });
  }
}

export default CarouselTiles;
