import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  EffectFade
]);

class Carousel {
  selector;
  element;
  carousel;
  settings = {};
  defaultSettings = {
    direction: 'horizontal',
    loop: true
  };

  constructor(selector, settings) {
    this.selector = selector;
    this.settings = {
      ...this.defaultSettings,
      ...settings,

    };
  }

  cache() {
    const { selector } = this;

    this.element = selector instanceof HTMLElement ? selector : document.querySelector(selector);
  }

  getSettings() {
    const { element, settings } = this;

    const paginationElement = element.querySelector('.js-carousel-pagination');
    const navPrevElement = element.querySelector('.js-carousel-nav-prev');
    const navNextElement = element.querySelector('.js-carousel-nav-next');

    return {
      ...settings,
      pagination: settings.pagination ? {
        el: paginationElement,
        ...settings.pagination
      } : false,
      navigation: settings.navigation ? {
        prevEl: navPrevElement,
        nextEl: navNextElement,
        ...settings.pagination
      } : false
    };
  }

  initCarousel() {
    const { element } = this;

    if (!element) {
      return;
    }

    const settings = this.getSettings();

    this.carousel = new Swiper(element, settings);
  }

  init() {
    const { selector } = this;

    if (selector) {
      this.cache();
      this.initCarousel();
    }
  }
}

export default Carousel;
