class FiltersTiles {
  constructor() {
    this.filtersWrapper = document.querySelector('.js-filters-tiles');
    this.contentItems = document.querySelectorAll('.js-filters-tiles-content');
  }

  init() {
    const {
      filtersWrapper,
      contentItems
    } = this;

    if (!filtersWrapper || contentItems.length === 0) {
      return;
    }

    const filters = filtersWrapper.querySelectorAll('button');

    filters.forEach((filterItem) => {
      const filter = filterItem.dataset.filter;

      filterItem.addEventListener('click', (e) => {
        e.preventDefault();

        filters.forEach((f) => {
          f.classList.toggle('is-active', f === filterItem);
        });

        contentItems.forEach((item) => {
          const contentItemFilter = item.dataset.filter;

          item.classList.toggle('is-active', filter === contentItemFilter);
        });
      });
    });
  }
}

export default FiltersTiles;
