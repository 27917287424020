import Carousel from './carousel';

class CarouselImages extends Carousel {
  constructor(selector = '.js-carousel-images', settings = {}) {
    super(selector, {
      ...settings,
      pagination: {
        clickable: true
      },
      autoplay: true
    });
  }

  initCarousel() {
    const { element } = this;

    if (!element) {
      return;
    }

    const carouselImagesContentWrapper = element.closest('.js-carousel-images-content-wrapper');
    const carouselImagesContent = carouselImagesContentWrapper && carouselImagesContentWrapper.querySelector('.js-carousel-images-content');

    if (carouselImagesContent) {
      const carouselContent = new Carousel(carouselImagesContent, {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        allowTouchMove: false,
        loop: false
      });
      carouselContent.init();

      this.settings.on = {
        ...this.settings.on,
        activeIndexChange: ({ realIndex }) => {
          carouselContent.carousel.slideTo(realIndex, 1000);
        }
      };
    }

    super.initCarousel();
  }
}

export default CarouselImages;
