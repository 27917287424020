class ModalVideo {
  constructor() {
    this.modalToggles = document.querySelectorAll('.js-modal-video-toggle');

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalEsc = this.closeModalEsc.bind(this);
  }

  bind() {
    const { modalToggles } = this;

    modalToggles.forEach((item) => {
      item.addEventListener('click', this.openModal);
    });
  }

  initModal(modal) {
    const modalClose = modal.querySelector('.js-modal-video-close');

    if (modalClose) {
      modalClose.addEventListener('click', this.closeModal);
    }

    document.addEventListener('keyup', this.closeModalEsc);
  }

  openModal(e) {
    e.preventDefault();

    const videoUrl = e.currentTarget.dataset.videoUrl;

    document.documentElement.classList.add('h-overflow-hidden');

    const modal = document.createElement('div');
    modal.classList.add('m-modal-video', 'js-modal-video');
    modal.innerHTML = `
      <div class="m-modal-video__inner">
        <button type="button" class="m-modal-video__close js-modal-video-close">
          <svg viewBox="0 0 387 387">
            <path d="M387 46L341 0 193 148 46 0 0 46l148 147L0 341l46 46 147-148 148 148 46-46-148-148z" />
          </svg>
        </button>

        <div class="m-modal-video__video">
          <video controls autoplay>
            <source src="${videoUrl}" type="video/mp4">
          </video>
        </div>
      </div>
    `;

    modal.toggleElement = e.currentTarget;

    if (e.currentTarget.tagName === 'VIDEO') {
      e.currentTarget.pause();
    }

    this.initModal(modal);

    document.body.appendChild(modal);

    return this;
  }


  closeModal(e) {
    if (e) {
      e.preventDefault();
    }

    const modals = document.querySelectorAll('.js-modal-video');

    modals.forEach((modal) => {
      if (modal.toggleElement && modal.toggleElement.tagName === 'VIDEO') {
        modal.toggleElement.play();
      }

      document.body.removeChild(modal);
    });

    document.documentElement.classList.remove('h-overflow-hidden');
    document.removeEventListener('keyup', this.closeModalEsc);

    return this;
  }

  closeModalEsc(e) {
    if (e.keyCode === 27) {
      this.closeModal();
    }

    return this;
  }

  init() {
    this.bind();
  }
}

export default ModalVideo;
