class ScrollAccordion {
  selector;
  element;
  items;
  observer;

  constructor(selector = '.js-scroll-accordion') {
    this.selector = selector;
    this.observer = 'IntersectionObserver' in window ? new IntersectionObserver(this.onIntersection, {
      root: null,
      rootMargin: '-1px'
    }) : null;
  }

  cache() {
    const { selector } = this;

    this.element = selector instanceof HTMLElement ? selector : document.querySelector(selector);
    this.items = this.element && this.element.querySelectorAll('.js-scroll-accordion-item');
  }

  onIntersection(elements) {
    elements.forEach((element) => {
      if (element.isIntersecting === true) {
        console.log(element);
      }
    });
  }

  initAccordion() {
    const { observer, element } = this;

    if (!element) {
      return;
    }

    if (observer) {
      observer.observe(element);
    } else {
      console.log('show all'); // @TODO
    }
  }

  init() {
    const { selector } = this;

    if (selector) {
      this.cache();
      this.initAccordion();
    }
  }
}

export default ScrollAccordion;
